import React, { useState } from "react";
import "./navbar.css";
import logo from "../../assets/images/logo.png";
import sidebarIcon from "../../assets/icons/burger.png";
import Sidebar from "../sidebar/sidebar";
import ContactusModal from "../contact-us/contactus-modal";
import { motion } from "framer-motion";

const Navbar = ({ featuresRef, pricingRef, contactUsRef }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOffCanvas = () => {
    setIsOpen(!isOpen);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <section className="flex justify-center lg:fixed bg_f9f9f9 h-fit z-50">
      <nav className="flex justify-between items-center fixed lg:static bg_f9f9f9 w-screen mx-auto lg:max-w-[90%] p-3">
        <div onClick={handleScrollToTop}>
          <img width={60} src={logo} alt="adair" />
        </div>

        <button onClick={toggleOffCanvas} className="lg:hidden">
          <img src={sidebarIcon} alt="-" />
        </button>

        <div className="hidden lg:flex w-1/2 justify-between navbar-tab-container  max-w-[28rem] fs_20">
          <label
            className="cursore_pointer"
            onClick={() => scrollToSection(featuresRef)}
          >
            Features
          </label>
          <label
            className="cursore_pointer"
            onClick={() => scrollToSection(pricingRef)}
          >
            Pricing
          </label>
          <label
            className="cursore_pointer"
            onClick={() => scrollToSection(contactUsRef)}
          >
            Contact Us
          </label>
        </div>

        <motion.button
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 400, damping: 5 }}
          type="button"
          onClick={() => setIsModalOpen(true)}
          className="hidden lg:flex start-free-btn rounded-md px-3 py-2 fw_600 fs_20"
        >
          Start Free
        </motion.button>
      </nav>
      <Sidebar
        featuresRef={featuresRef}
        pricingRef={pricingRef}
        contactUsRef={contactUsRef}
        toggleOffCanvas={toggleOffCanvas}
        show={isOpen}
        modalOpen={() => setIsModalOpen(true)}
      />
      <ContactusModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </section>
  );
};

export default Navbar;
