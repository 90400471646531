import React from "react";
import rightArrow from "../../assets/icons/right-arrow.png";
import productUpload from "../../assets/images/features/product.png";
import theme from "../../assets/images/features/theme.png";
import enquery from "../../assets/images/features/enquery.png";
import inventory from "../../assets/images/features/product-list.png";
import commesion from "../../assets/images/features/product-list.png";
import Abandoned from "../../assets/images/features/Abandoned.png";
import offers from "../../assets/images/features/offers.png";
import variation from "../../assets/images/features/variation.png";
import notifaction from "../../assets/images/features/notifaction.png";
import login from "../../assets/images/features/login.png";
import { motion } from "framer-motion";

const Features = ({ setIsModalOpen = () => {} }) => {
  const featuresData = [
    {
      title: "Managed Product Upload",
      description:
        "Assisted Product Upload for Ease Upload unlimited Products Effortlessly",
      url: "",
      img: productUpload,
    },
    {
      title: "Theme Customization",
      description: "Change the theme with a click Choose Store color instantly",
      url: "",
      img: theme,
    },
    {
      title: "Custom Domain Support",
      description:
        "We assist with Custom Domain Get Free Domain with Adair.store",
      url: "",
      img: productUpload,
    },
    {
      title: "Top Product Enquiry/ Sold",
      description:
        "Identify your Best-Selling Product Stock up based on your  client's interest",
      url: "",
      img: enquery,
    },
    {
      title: "Inventory Management",
      description:
        "Monitor Stock in Real-Time Manage inventory, never fall below MOQ",
      url: "",
      img: inventory,
    },
    {
      title: "Low Commission",
      description:
        "We don't take cuts in your profits.Enjoy Lowest to 0 Commission",
      url: "",
      img: commesion,
    },
    {
      title: "Abandoned Cart",
      description:
        "Identify Customers who abandoned the Carts.Run targeted marketing campaigns to convert them!",
      url: "",
      img: Abandoned,
    },
    {
      title: "Offers and Discounts",
      description:
        "Attract Buyers with lucrative Deals. Create Seasonal offers, Exclusive discounts, Coupons, etc...",
      url: "",
      img: offers,
    },
    {
      title: "Product Variations",
      description:
        "Manage multiple product variations with ease.Simplify store Maintenance with ease.",
      url: "",
      img: variation,
    },
    {
      title: "Notifications to Customers",
      description:
        "Keep customers informed.Send in-app notifications with ease.",
      url: "",
      img: notifaction,
    },
    {
      title: "Free Store Available",
      description:
        "Explore Basic Adair Features at No Cost.Launch Freemium Store for FREE",
      url: "",
      img: login,
    },
  ];

  return (
    <section>
      <h1 className="text-center my-5 mt-10 md:my-10 fs_58 fs_lg_50 fs_md_40 fs_sm_30 fs_xs_24 fw_700">
        Merchant-Friendly Features
      </h1>

      <div className="mx-auto max-w-[90%] lg:pt-10">
        {featuresData?.map((item, index) =>
          index % 2 === 0 ? (
            <motion.div
              initial="hidden"
              animate="visible"
              whileInView={{
                opacity: 1,
                transition: {
                  staggerChildren: 0.1,
                  delay: index * 0.05,
                },
              }}
              key={index}
              className="flex flex-col lg:flex-row justify-between items-center gap-5 lg:gap-10 my-10"
            >
              <div className="max-w-[80rem] lg:w-2/3">
                <img src={item.img} alt="features" />
              </div>
              <div className="lg:w-2/3 lg:w-1/2 max-w-[100%] lg:max-w-[35%] 2xl:max-w-[30rem] mx-auto lg:mx-0 text-center lg:text-left">
                <h6 className="fw_900 fs_30 fs_lg_26 fs_md_22 fs_sm_20">
                  {item?.title}
                </h6>
                <p className="color_5A5A5A fs_20 fs_lg_16 fs_md_14">
                  {item?.description}
                </p>
                <div className="flex justify-center lg:justify-start">
                  <button
                    type="button"
                    onClick={() => setIsModalOpen(true)}
                    className="try-now-btn px-5 py-2 rounded-md flex items-center my-3 fs_lg_14"
                  >
                    Try Now <img className="ml-4" src={rightArrow} alt="->" />
                  </button>
                </div>
              </div>
            </motion.div>
          ) : (
            <motion.div
              // variants={listVariants}
              initial="hidden"
              animate="visible"
              key={index}
              className="flex flex-col lg:flex-row justify-between items-center gap-5 lg:gap-10 my-10"
            >
              <div className="max-w-[80rem] lg:w-2/3 lg:hidden">
                <img src={item.img} alt="features" />
              </div>
              <div className="lg:w-2/3 lg:w-1/2 max-w-[100%] lg:max-w-[35%] 2xl:max-w-[30rem] mx-auto lg:mx-0 text-center lg:text-left">
                <h6 className="fw_900 fs_30 fs_lg_26">{item?.title}</h6>
                <p className="color_5A5A5A fs_20 fs_lg_16">
                  {item?.description}
                </p>
                <div
                  type="button"
                  onClick={() => setIsModalOpen(true)}
                  className="flex justify-center lg:justify-start"
                >
                  <button className="try-now-btn px-5 py-2 rounded-md flex items-center my-3 fs_lg_14">
                    Try Now <img className="ml-4" src={rightArrow} alt="->" />
                  </button>
                </div>
              </div>
              <div className="max-w-[80rem] lg:w-2/3 hidden lg:block">
                <img src={item.img} alt="features" />
              </div>
            </motion.div>
          )
        )}
      </div>
    </section>
  );
};

export default Features;
