import React, { useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { Form, Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import close from "../../assets/icons/closeIcon.svg";
import axios from "axios";
import SuccessModal from "./success-modal";
import FailureModal from "./failure-modal";

const Modal = ({ isOpen, onClose }) => {
  const [onSuccess, setOnSuccess] = useState(false);
  const [onFailure, setOnFailure] = useState(false);
  const phoneregex = /^([+]\d{2})?\d{10}$/;

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    message: "",
  };
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().trim().required("Required"),
    last_name: Yup.string().trim(),
    email: Yup.string().email("Not a proper email").trim().required("Required"),
    mobile: Yup.string()
      .trim()
      .matches(phoneregex, "Invalid Phone Number")
      .required("Required"),
    message: Yup.string().trim().required("Required"),
  });

  const onSubmit = async (values) => {
    console.log(values, "values");
    try {
      const res = await axios.post(
        "https://api.adair.store/api/common/store-contact-us",
        values
      );
      setOnSuccess(true);
      console.log("Success:", res.data);
    } catch (error) {
      setOnFailure(true);
      console.error("Error:", error);
    }
  };
  return (
    <>
      <SuccessModal isOpen={onSuccess} onClose={() => setOnSuccess(false)} />
      <FailureModal isOpen={onFailure} onClose={() => setOnFailure(false)} />
      <Dialog open={isOpen} onClose={onClose} className="relative z-50">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-black/30  ease-out data-[closed]:opacity-0"
        />
        <div className="fixed inset-0 flex w-screen items-center justify-center p-2">
          <DialogPanel className="w-full max-w-[50rem] space-y-4 border bg-white p-8 lg:p-10 relative rounded-md">
            <button onClick={onClose} className="absolute right-6 top-6">
              <img src={close} alt="x" />
            </button>
            <h1 className="fs_40 fs_sm_30 fs_xs_24 fw_700 flex justify-center mb-10">
              Contact Us
            </h1>
            <div>
              <h1 className="fs_20 fs_sm_18 fw_700">
                Fill the form, we will Reach you
              </h1>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                <Form>
                  {" "}
                  <div className="flex flex-col md:flex-row ">
                    <div className="md:w-1/2 my-5 relative">
                      <label className="fw_700 fs_18 fs_lg_16 primary-color w-full">
                        First Name<span className="color_F24445">*</span>
                      </label>
                      <div className="pl-3 bg_F5F6FF rounded-md py-1 md:mr-4">
                        <Field
                          name="first_name"
                          type="text"
                          className="contact-input w-11/12"
                          placeholder="Enter Your First Name"
                        />
                      </div>
                      <p className="absolute mt-1 color_F24445">
                        <ErrorMessage name={"first_name"}>
                          {(msg) => (
                            <>
                              <span className={` ms-0 ms-lg-1 fs_12 fw_600`}>
                                {msg}
                              </span>
                            </>
                          )}
                        </ErrorMessage>
                      </p>
                    </div>
                    <div className="md:w-1/2 md:my-5 relative">
                      <label className="fw_700 fs_18 fs_lg_16 primary-color w-full">
                        Last Name
                      </label>
                      <div className="pl-3 bg_F5F6FF rounded-md py-1">
                        <Field
                          name="last_name"
                          type="text"
                          className="contact-input w-11/12"
                          placeholder="Enter Your Last Name"
                        />
                      </div>
                      <p className="absolute mt-1 color_F24445">
                        <ErrorMessage name={"last_name"}>
                          {(msg) => (
                            <>
                              <span className={` ms-0 ms-lg-1 fs_12 fw_600`}>
                                {msg}
                              </span>
                            </>
                          )}
                        </ErrorMessage>
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row">
                    <div className="md:w-1/2 my-5 relative">
                      <label className="fw_700 fs_18 fs_lg_16 primary-color w-full">
                        Contact Number<span className="color_F24445">*</span>
                      </label>

                      <div className="pl-3 bg_F5F6FF rounded-md py-1 md:mr-4">
                        <Field
                          name="mobile"
                          type="text"
                          className="contact-input w-11/12"
                          placeholder="Enter Your Contact Number"
                        />
                      </div>
                      <p className="absolute mt-1 color_F24445">
                        <ErrorMessage name={"mobile"}>
                          {(msg) => (
                            <>
                              <span className={` ms-0 ms-lg-1 fs_12 fw_600`}>
                                {msg}
                              </span>
                            </>
                          )}
                        </ErrorMessage>
                      </p>
                    </div>
                    <div className="md:w-1/2 md:my-5 relative">
                      <label className="fw_700 fs_18 fs_lg_16 primary-color w-full">
                        Email<span className="color_F24445">*</span>
                      </label>
                      <div className="pl-3 bg_F5F6FF rounded-md py-1">
                        <Field
                          name="email"
                          type="text"
                          className="contact-input w-11/12"
                          placeholder="Enter Your mail id"
                        />
                      </div>
                      <p className="absolute mt-1 color_F24445">
                        <ErrorMessage name={"email"}>
                          {(msg) => (
                            <>
                              <span className={` ms-0 ms-lg-1 fs_12 fw_600`}>
                                {msg}
                              </span>
                            </>
                          )}
                        </ErrorMessage>
                      </p>
                    </div>
                  </div>
                  <div className="my-5 relative">
                    <label className="fw_700 fs_18 fs_lg_16 primary-color w-full">
                      Message<span className="color_F24445">*</span>
                    </label>
                    <div className="pl-3 bg_F5F6FF rounded-md py-1">
                      <Field
                        as="textarea"
                        name="message"
                        rows={5}
                        style={{ resize: "none" }}
                        type="text"
                        className="contact-input w-full "
                        placeholder="Your Message"
                      />
                    </div>
                    <p className="absolute mt-1 color_F24445">
                      <ErrorMessage name={"message"}>
                        {(msg) => (
                          <>
                            <span className={` ms-0 ms-lg-1 fs_12 fw_600`}>
                              {msg}
                            </span>
                          </>
                        )}
                      </ErrorMessage>
                    </p>
                  </div>
                  <div className="text-end w-full">
                    <button
                      // onClick={onClose}
                      type="submit"
                      className="try-now-btn fs_18 fs_lg_16 fw_600 px-10 py-2 rounded-md md:mt-5"
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
};

export default Modal;
