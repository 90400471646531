import React from "react";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";

const FooterComponent = () => {
  return (
    <footer className="md:flex md:flex-wrap lg:flex-row justify-between max-w-[90%] mx-auto my-10 pt-10">
      <div className="md:w-1/4 lg:w-2/12">
        <img width={60} src={logo} alt="adair" />
        <p className="mt-3 fs_sm_14">
          Discover the Right Fit for your writing with Adair Store
        </p>
      </div>
      <div className="my-5 md:my-0">
        <h1 className="fs_26 fs_lg_22 fs_sm_20 fw_700">Legal</h1>
        <div className="color_7A7A7A">
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={"/terms-and-conditions"}
          >
            <p className="my-2 md:my-4 fs_sm_14">Terms & Conditions</p>
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={"/privacy-policy"}
          >
            <p className="my-2 md:my-4 fs_sm_14">Privacy Policy</p>
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={"/refund-and-Cancellation"}
          >
            <p className="fs_sm_14">Refund and cancellation policy</p>
          </Link>
        </div>
      </div>
      <div className="my-5 md:my-0">
        <h1 className="fs_26 fs_lg_22 fs_sm_20 fw_700">Social</h1>
        <div className="color_7A7A7A">
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={"https://www.linkedin.com/company/adair-store/"}
          >
            <p className="my-2 md:my-4 fs_sm_14">LinkedIn</p>
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={"https://www.instagram.com/_adair.store_/"}
          >
            <p className="my-2 md:my-4 fs_sm_14">Instagram</p>
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={"https://www.facebook.com/people/Adairstore/61554389197365/"}
          >
            <p className="my-2 md:my-4 fs_sm_14">Facebook</p>
          </Link>
        </div>
      </div>
      <div className="lg:w-4/12">
        <h1 className="fs_26 fs_lg_22 fs_sm_20 fw_700">Contact</h1>
        <div className="color_7A7A7A">
          <p className="my-2 md:my-4 fs_sm_14">sales@adair.store</p>
          <p className="my-2 md:my-4 fs_sm_14">(+91) - 6364840327 / 26</p>
          <p className="md:w-9/12 fs_sm_14 pr-5">
            2nd Floor, Emirates Arcade, 80 Feet Rd, behind Water Tank,
            Manchegowdana koppalu, II Stage, Vijayanagar, Mysuru, Karnataka
            570017.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
