import React from "react";
import FooterComponent from "../../components/footer/footer";
import LogoNavbar from "../../components/navbar/logo-navbar";

const RefundCancellation = () => {
  return (
    <section>
      <LogoNavbar />
      <section className="m-4 pt-16 lg:pt-12 lg:m-5">
        <h1 className="text-center fs_40 fs_lg_30 fs_sm_24 fw_600 primary-color">
          Refund and Cancellation Policy
        </h1>
        <div className="w-11/12 md:w-3/4 mx-auto text-justify my-10 lg:my-20">
          <p className="fs_18 fs_lg_16 my-2">
            Our focus is complete customer satisfaction. In the event, if you
            are displeased with the services provided, we will refund back the
            money, provided the reasons are genuine and proved after
            investigation. Please read the fine prints of each deal before
            buying it, it provides all the details about the services or the
            product you purchase.
          </p>
          <p className="fs_18 fs_lg_16 my-2">
            In case of dissatisfaction from our services, clients have the
            liberty to cancel their projects and request a refund from us. Our
            Policy for the cancellation and refund will be as follows:
          </p>
          <h4 className="fs_26 fs_lg_24 fs_sm_20 fw_700">
            Cancellation Policy
          </h4>
          <p className="fs_18 fs_lg_16 my-2">
            For Cancellations please contact the us via contact us link.{" "}
          </p>
          <p className="fs_18 fs_lg_16 my-2">
            Requests received later than 1 business days prior to the end of the
            current service period will be treated as cancellation of services
            for the next service period.
          </p>
          <h4 className="fs_26 fs_lg_24 fs_sm_20 fw_700">Refund Policy</h4>
          <p className="fs_18 fs_lg_16 my-2">
            We will try our best to create the suitable design concepts for our
            clients.
          </p>
          <p className="fs_18 fs_lg_16 my-2">
            In case any client is not completely satisfied with our products we
            can provide a refund.{" "}
          </p>
          <p className="fs_18 fs_lg_16 my-2">
            If paid by credit card, refunds will be issued to the original
            credit card provided at the time of purchase and in case of payment
            gateway name payments refund will be made to the same account.
          </p>
        </div>
      </section>
      <FooterComponent />
    </section>
  );
};

export default RefundCancellation;
