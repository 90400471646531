import React from "react";
import tick from "../../assets/icons/pricing/tick.svg";
import wrong from "../../assets/icons/pricing/X.svg";

const PricingComponent = ({ setIsModalOpen = () => {} }) => {
  const plansList = [
    {
      title: "3 Day Trial",
      subTitle: "Free",
      description: "Perfect for Beginners",
      buttonName: "Get Started FREE",
      list: [
        {
          name: "Store",
          available: true,
        },
        {
          name: "Domain",
          available: false,
        },
        {
          name: "Product upload assistance",
          available: false,
        },
        {
          name: "Single theme",
          available: true,
        },
        {
          name: "COD only",
          available: true,
        },
        {
          name: "Delivery Optimisation",
          available: false,
        },
      ],
    },
    {
      title: "Standard",
      subTitle: "Rs. 7000",
      description: "Ideal for Growing Businesses",
      buttonName: "Explore Standard",
      list: [
        {
          name: "Store",
          available: true,
        },
        {
          name: "Domain",
          available: true,
        },
        {
          name: "Product upload assistance",
          available: false,
        },
        {
          name: "Single theme",
          available: true,
        },
        {
          name: "COD only",
          available: true,
        },
        {
          name: "Delivery Optimisation",
          available: true,
        },
      ],
    },
    {
      title: "Premium",
      subTitle: "Rs. 15000",
      description: "Designed for Scaled Business",
      buttonName: "Upgrade PREMIUM",
      list: [
        {
          name: "Store",
          available: true,
        },
        {
          name: "Domain",
          available: true,
        },
        {
          name: "Product upload assistance",
          available: true,
        },
        {
          name: "Single theme",
          available: true,
        },
        {
          name: "COD only",
          available: true,
        },
        {
          name: "Delivery Optimisation",
          available: true,
        },
      ],
    },
  ];
  return (
    <section className="my-10 lg:my-0 lg:py-24">
      <h1 className="text-center fs_58 fs_lg_50 fs_md_40 fs_sm_30 fs_xs_24 fw_700">
        Flexible Pricing Plan
      </h1>
      <div className="w-full max-w-[90rem] flex flex-col gap-4 lg:flex-row items-center lg:justify-between mx-auto px-10">
        {plansList?.map((item, index) => (
          <div
            key={index}
            className="my-5 md:my-5 bg_F5F6FF p-5 xl:p-10 rounded-lg"
          >
            <h1
              className={
                index === 0
                  ? "text-center fs_30 fs_lg_26 fs_sm_22 fw_700 color_5A5A5A"
                  : "text-center fs_30 fs_lg_26 fs_sm_22 fw_700"
              }
            >
              {item?.title}
            </h1>
            <h1
              className={
                item?.subTitle !== "Free"
                  ? "text-center fs_30 fs_lg_26 fs_sm_22 fw_700 color_4D54FF"
                  : "text-center fs_30 fs_lg_26 fs_sm_22 fw_700"
              }
            >
              {item?.subTitle}
            </h1>
            <p className="text-center fs_20 fs_lg_18 fs_sm_16 fw_600">
              {item?.description}
            </p>
            <div className="py-5 md:py-10">
              {item?.list?.map((listItem, index) => (
                <div key={index} className="flex my-4">
                  {listItem?.available ? (
                    <img width={16} src={tick} alt="./" />
                  ) : (
                    <img width={18} src={wrong} alt="./" />
                  )}
                  <label className="mx-3 color_5A5A5A fs_20 fs_lg_18 fs_sm_16">
                    {listItem?.name}
                  </label>
                </div>
              ))}
            </div>
            <button
              type="button"
              onClick={() => setIsModalOpen(true)}
              className="try-now-btn fs_20 fs_lg_18 fs_sm_16 fw_600 px-7 py-2 rounded-md"
            >
              {item?.buttonName}
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default PricingComponent;
