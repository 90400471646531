import React, { useRef, useState } from "react";
import "./main.css";
import Navbar from "../../components/navbar/navbar";
import Features from "../../components/features/features";
import BusinessFeatures from "../../components/features/business-features";
import PricingComponent from "../../components/pricing/pricing";
import InnovationsComponent from "../../components/innovations/innovations";
import ContactUsComponent from "../../components/contact-us/contact-us";
import FooterComponent from "../../components/footer/footer";
import ContactusModal from "../../components/contact-us/contactus-modal";
import DeveloperFeaturesComponent from "../../components/features/developer-features";
import { motion } from "framer-motion";

const Main = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const featuresRef = useRef(null);
  const pricingRef = useRef(null);
  const contactUsRef = useRef(null);

  const headingVariants = {
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
    hidden: { opacity: 0, x: -50 },
  };

  return (
    <>
      <ContactusModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <Navbar
        featuresRef={featuresRef}
        pricingRef={pricingRef}
        contactUsRef={contactUsRef}
      />
      <main className="w-full max-w-[90rem] mt-24 lg:mt-0 lg:h-screen mx-auto text-center flex items-center justify-center p-4">
        <div>
          <motion.div
            variants={headingVariants}
            initial="hidden"
            animate="visible"
          >
            <p
              variants={headingVariants}
              initial="hidden"
              animate="visible"
              className="fs_78 fs_lg_58 fs_md_50 fs_sm_40 fs_xs_24 fw_700 primary-color"
            >
              Decide Your Buyers Next Buy!
            </p>
            <p className="fs_58 fs_lg_38 fs_md_32 fs_sm_30 fs_xs_20 fw_700 primary-color lg:my-5">
              No <span className="mask1">Artificial</span> But{" "}
              <span className="mask2">Intelligence</span> Enabled
            </p>
            <p className="color_5A5A5A fs_30 fs_lg_20 fs_md_18 fs_sm_18 fw_700 lg:my-10 my-5">
              Built Around You, Your User, and Your Product
            </p>
            <p className="primary-color fs_20 fs_lg_16 fs_md_14 lg:my-10 my-5">
              Launch Merchant-Friendly E-commerce website Now!
            </p>
          </motion.div>

          <motion.button
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ type: "spring", stiffness: 900, damping: 40 }}
            type="button"
            onClick={() => setIsModalOpen(true)}
            className="get-started-btn fs_20 fs_lg_16 fs_xs_14 fw_700 px-9 py-3 rounded-lg md:mt-5"
          >
            Get Started
          </motion.button>
        </div>
      </main>

      <Features setIsModalOpen={setIsModalOpen} />

      <section ref={featuresRef}>
        <BusinessFeatures />
      </section>

      <DeveloperFeaturesComponent />

      <section ref={pricingRef}>
        <PricingComponent setIsModalOpen={setIsModalOpen} />
      </section>

      <section className="get-started-container text-center py-10">
        <div className="w-11/12 mx-auto">
          {" "}
          <h1 className="fs_30 fs_lg_24 fs_sm_20 fs_xs_18 fw_700 mt-8">
            Start your E-commerce Store for Free
          </h1>
          <p className="fs_20 fs_lg_18 fs_sm_16 fs_xs_14">
            Sell Online and see the <span className="fw_600">GROWTH</span>
          </p>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            type="button"
            onClick={() => setIsModalOpen(true)}
            className="try-now-btn fs_20 fs_lg_18 fs_sm_16 fw_600 px-7 py-2 rounded-md m-8"
          >
            Get Started
          </motion.button>
        </div>
      </section>

      <InnovationsComponent setIsModalOpen={setIsModalOpen} />

      <section ref={contactUsRef}>
        <ContactUsComponent setIsModalOpen={setIsModalOpen} />
      </section>

      <FooterComponent />
    </>
  );
};

export default Main;
