import React, { useEffect } from "react";
import logo from "../../assets/images/logo.png";
import closeIcon from "../../assets/icons/close.svg";
import "../navbar/navbar.css";
import { motion } from "framer-motion";

const Sidebar = ({
  toggleOffCanvas = false,
  show = false,
  modalOpen = () => {},
  featuresRef,
  pricingRef,
  contactUsRef,
}) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [show]);

  const scrollToSection = (sectionRef) => {
    const offset = window.innerWidth <= 768 ? 100 : 60;
    const elementPosition =
      sectionRef.current.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={`fixed inset-y-0 left-0 w-full bg-white shadow-lg transform transition-transform duration-300 h-screen ${
        show ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <div className="flex justify-between items-end mx-5 my-3 px-1">
        <div>
          <img width={40} src={logo} alt="adair" />
        </div>
        <button
          onClick={toggleOffCanvas}
          className="px-4 py-2 text-gray-500 hover:text-gray-700"
        >
          <img width={20} src={closeIcon} alt="X" />
        </button>
      </div>

      <div className="p-4 w-2/3 mx-auto h-[60vh] flex items-center">
        <div>
          <button
            onClick={() => {
              scrollToSection(featuresRef);
              toggleOffCanvas();
            }}
            className="w-full my-5"
          >
            Features
          </button>
          <button
            onClick={() => {
              scrollToSection(pricingRef);
              toggleOffCanvas();
            }}
            className="w-full my-5"
          >
            Pricing
          </button>
          <button
            onClick={() => {
              scrollToSection(contactUsRef);
              toggleOffCanvas();
            }}
            className="w-full my-5"
          >
            Contact Us
          </button>
          <motion.button
            type="button"
            whileTap={{ scale: 0.9 }}
            whileHover={{ scale: 1.1 }}
            transition={{ type: "spring", stiffness: 400, damping: 5 }}
            onClick={() => {
              modalOpen();
              toggleOffCanvas();
            }}
            className="start-free-btn rounded-md w-full  py-2 fw_600 my-5"
          >
            Start Free
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
