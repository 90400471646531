import React from "react";
import Main from "../modules/main/main";
import TermsConditions from "../modules/policies/terms-conditions";
import { Route, Routes } from "react-router-dom";
import PrivacyPolicy from "../modules/policies/privacy-policy";
import RefundCancellation from "../modules/policies/refund-cancellation";

const MainRoute = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/terms-and-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/refund-and-Cancellation"
          element={<RefundCancellation />}
        />
      </Routes>
    </>
  );
};

export default MainRoute;
