import React from "react";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import success from "../../assets/icons/success.png";

const SuccessModal = ({ isOpen = false, onClose = () => {} }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-black/30  ease-out data-[closed]:opacity-0"
      />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-2">
        <DialogPanel className="max-w-[30rem] space-y-4 border bg-white p-8 lg:p-10 relative rounded-md">
          <div className="flex justify-center">
            <img width={80} src={success} alt="./" />
          </div>

          <p className="fs_20 fw_600 text-center">
            Thank you for your inquiry. Our sales team will be in touch with you
            shortly.
          </p>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default SuccessModal;
