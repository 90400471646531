import React from "react";

const DeveloperFeaturesComponent = () => {
  const featuresList = [
    {
      title: "Headless E-commerce",
      description:
        "Separate your E-commerce Front end from the Back Enable Easy API Integration",
    },
    {
      title: "Themes",
      description:
        "Select multiple in Built Themes for Different store Customize according to Users.",
    },
    {
      title: "Plugins",
      description:
        "Experience the tools built on industry-leading frameworks Ensure superior performance and scalability",
    },
    {
      title: "Node Express React",
      description: "Built on popular platforms Easy customization.",
    },
    {
      title: "Comprehensive Documentation",
      description:
        "Access detailed, well-organized documentation for every feature and tool.Get started quickly with clear examples and best practices. ",
    },
    {
      title: "Active Community Support",
      description:
        "Join a vibrant community for collaborative problem-solving. Benefit from ongoing updates, plugins, and resources.",
    },
    {
      title: "Modular Architecture",
      description:
        "Easy upgrades and maintenance. Customize components without disrupting your existing setup",
    },
  ];
  return (
    <section className="lg:py-10 lg:my-10">
      <h1 className="text-center my-10 fs_58 fs_lg_50 fs_md_40 fs_sm_30 fs_xs_24 fw_700">
        Developer Friendly Features
      </h1>
      <div className="flex flex-wrap justify-between gap-2 lg:w-11/12 mx-auto lg:px-10">
        {featuresList?.map((item, index) => (
          <div
            key={index}
            className="text-center w-10/12 lg:w-full lg:max-w-[24rem] xl:max-w-[28rem] 2xl:max-w-[32rem] mb-10 mx-auto lg:mx-0 developer-feature-card py-14 rounded-md"
          >
            {" "}
            <div>
              <p className="fs_26 fs_lg_22 fs_sm_20 fs_xs_18 fw_700">
                {item?.title}
              </p>
              <p className="color_5A5A5A fs_20 fs_lg_18 fs_sm_16 fs_xs_14 px-4 mt-4">
                {item?.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default DeveloperFeaturesComponent;
