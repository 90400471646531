import React from "react";
import rocket from "../../assets/icons/rocket.svg";

const InnovationsComponent = ({ setIsModalOpen = () => {} }) => {
  const ListData = [
    "Theme Generator",
    "Recommender System",
    "Auto-Generated Product Description, Enhancing on-page SEO",
    "Whatsapp Bot",
  ];
  return (
    <section className="my-10 lg:mt-20">
      <h1 className="fs_58 fs_lg_50 fs_md_40 fs_sm_30 fs_xs_24 fw_700 flex justify-center items-center">
        Upcoming Innovations
        <img
          className="animated-box ml-5 w-[2rem] lg:w-[4rem]"
          src={rocket}
          alt="/\"
        />
      </h1>
      <p className="fs_30 fs_lg_24 fs_sm_20 fs_xs_18 fw_700 text-center">
        Artificial Intelligence
      </p>
      <div className="flex flex-col lg:flex-row w-full max-w-[90rem] mx-auto justify-between my-10 lg:my-20 gap-2 lg:p-2">
        {ListData?.map((item, index) => (
          <div
            key={index}
            className="w-full max-w-[80%] lg:max-w-[25rem] bg_F5F6FF mb-5 mx-auto p-10 lg:p-6 lg:px-10 rounded-md fs_sm_14 fw_600 flex items-center justify-center"
          >
            {item}
          </div>
        ))}
      </div>
      <div className="text-center lg:pt-10">
        <p className="fs_20 fs_lg_18 fs_sm_16 color_5A5A5A">
          Get your E-commerce game up
        </p>
        <button
          type="button"
          onClick={() => setIsModalOpen(true)}
          className="try-now-btn fs_20 fs_lg_18 fs_sm_16 fw_600 px-5 py-2 rounded-md mt-5"
        >
          Request Quote
        </button>
      </div>
    </section>
  );
};

export default InnovationsComponent;
