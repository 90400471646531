import React, { useState } from "react";
import "./conatct.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import SuccessModal from "./success-modal";
import FailureModal from "./failure-modal";
import axios from "axios";

const ContactUsComponent = ({ setIsModalOpen = () => {} }) => {
  const [onSuccess, setOnSuccess] = useState(false);
  const [onFailure, setOnFailure] = useState(false);
  const phoneregex = /^([+]\d{2})?\d{10}$/;
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    message: "",
  };
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().trim().required("Required"),
    last_name: Yup.string().trim(),
    email: Yup.string().email("Not a proper email").trim().required("Required"),
    mobile: Yup.string()
      .trim()
      .matches(phoneregex, "Invalid Phone Number")
      .required("Required"),
    message: Yup.string().trim().required("Required"),
  });

  const onSubmit = async (values) => {
    console.log(values);
    try {
      const res = await axios.post(
        "https://api.adair.store/api/common/store-contact-us",
        values
      );
      setOnSuccess(true);
      console.log("Success:", res.data);
    } catch (error) {
      setOnFailure(true);
      console.error("Error:", error);
    }
  };
  return (
    <section className="my-10 lg:my-5 lg:py-20">
      <SuccessModal isOpen={onSuccess} onClose={() => setOnSuccess(false)} />
      <FailureModal isOpen={onFailure} onClose={() => setOnFailure(false)} />
      <h1 className="fs_58 fs_lg_50 fs_md_40 fs_sm_30 fs_xs_24 fw_700 flex justify-center">
        Contact Us
      </h1>
      <div className="w-full max-w-[90rem] mx-auto flex flex-col xl:flex-row items-start lg:my-10 px-10 xl:px-5">
        <div className="w-full  md:w-6/12 md:max-w-[35rem]">
          <div className="my-5 xl:my-0">
            <h1 className="fs_30 fs_lg_24 fs_sm_20 fw_700">Contact details</h1>
            <p className="color_5A5A5A fs_20 fs_lg_16">Contact@adair.store</p>
            <p className="color_5A5A5A fs_20 fs_lg_16">(+91) - 6364840327 / 26</p>
          </div>
          <div className="my-5  lg:pt-5  xl:my-0">
            <h1 className="fs_30 fs_lg_24 fs_sm_20 fw_700">Address</h1>
            <p className="color_5A5A5A fs_20 fs_lg_16 sm:w-3/4">
              2nd Floor, Emirates Arcade, Vijaynagar 2nd Stage, Mysore,
              Karnataka 570017.
            </p>
          </div>
        </div>
        <div className="flex justify-center w-full mt-5 md:hidden">
          <button
            type="button"
            onClick={() => setIsModalOpen(true)}
            className="try-now-btn fs_20 fs_lg_18 fs_sm_16 fw_600 px-7 py-2 rounded-md"
          >
            Contact Us
          </button>
        </div>

        <div className="w-full xl:w-3/4 xl:max-w-[50rem] hidden md:block">
          <h1 className="fs_26 fs_lg_24 fs_sm_20 fw_700">
            Fill the form, we will Reach you
          </h1>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            <Form>
              <div className="mt-5">
                <div className="flex flex-col md:flex-row ">
                  <div className="md:w-1/2 my-10 relative">
                    <label className="fw_700 fs_20 fs_lg_18 fs_sm_16 primary-color w-full">
                      First Name<span className="color_F24445">*</span>
                    </label>
                    <div className="pl-3 bg_F5F6FF rounded-md py-2 md:mr-4 mt-2">
                      <Field
                        name="first_name"
                        type="text"
                        className="contact-input w-11/12"
                        placeholder="Enter Your First Name"
                      />
                    </div>
                    <p className="absolute mt-1 color_F24445">
                      <ErrorMessage name={"first_name"}>
                        {(msg) => (
                          <>
                            <span className={` ms-0 ms-lg-1 fs_12 fw_600`}>
                              {msg}
                            </span>
                          </>
                        )}
                      </ErrorMessage>
                    </p>
                  </div>
                  <div className="md:w-1/2 md:my-10">
                    <label className="fw_700 fs_20 fs_lg_18 fs_sm_16 primary-color w-full">
                      Last Name
                    </label>
                    <div className="pl-3 bg_F5F6FF rounded-md py-2 mt-2">
                      <Field
                        name="last_name"
                        type="text"
                        className="contact-input w-11/12"
                        placeholder="Enter Your Last Name"
                      />
                    </div>
                    <p className="absolute mt-1 color_F24445">
                      <ErrorMessage name={"last_name"}>
                        {(msg) => (
                          <>
                            <span className={` ms-0 ms-lg-1 fs_12 fw_600`}>
                              {msg}
                            </span>
                          </>
                        )}
                      </ErrorMessage>
                    </p>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row">
                  <div className="md:w-1/2 ">
                    <label className="fw_700 fs_20 fs_lg_18 fs_sm_16 primary-color w-full">
                      Contact Number<span className="color_F24445">*</span>
                    </label>
                    <div className="pl-3 bg_F5F6FF rounded-md py-2 mt-2 md:mr-4">
                      <Field
                        name="mobile"
                        type="text"
                        className="contact-input w-11/12"
                        placeholder="Enter Your Contact Number"
                      />
                    </div>
                    <p className="absolute mt-1 color_F24445">
                      <ErrorMessage name={"mobile"}>
                        {(msg) => (
                          <>
                            <span className={` ms-0 ms-lg-1 fs_12 fw_600`}>
                              {msg}
                            </span>
                          </>
                        )}
                      </ErrorMessage>
                    </p>
                  </div>
                  <div className="md:w-1/2">
                    <label className="fw_700 fs_20 fs_lg_18 fs_sm_16 primary-color w-full">
                      Email<span className="color_F24445">*</span>
                    </label>
                    <div className="pl-3 bg_F5F6FF rounded-md py-2 mt-2">
                      <Field
                        type="text"
                        name="email"
                        className="contact-input w-11/12"
                        placeholder="Enter Your mail id"
                      />
                    </div>
                    <p className="absolute mt-1 color_F24445">
                      <ErrorMessage name={"email"}>
                        {(msg) => (
                          <>
                            <span className={` ms-0 ms-lg-1 fs_12 fw_600`}>
                              {msg}
                            </span>
                          </>
                        )}
                      </ErrorMessage>
                    </p>
                  </div>
                </div>
                <div className="my-10 md:my-10">
                  <label className="fw_700 fs_20 fs_lg_18 fs_sm_16 primary-color w-full">
                    Message<span className="color_F24445">*</span>
                  </label>
                  <div className="pl-3 bg_F5F6FF rounded-md py-2 mt-2">
                    <Field
                      name="message"
                      as="textarea"
                      rows={5}
                      style={{ resize: "none" }}
                      type="text"
                      className="contact-input w-full"
                      placeholder="Your Message"
                    />
                  </div>
                  <p className="absolute mt-1 color_F24445">
                    <ErrorMessage name={"message"}>
                      {(msg) => (
                        <>
                          <span className={` ms-0 ms-lg-1 fs_12 fw_600`}>
                            {msg}
                          </span>
                        </>
                      )}
                    </ErrorMessage>
                  </p>
                </div>
                <div className="text-end w-full">
                  <button
                    type="submit"
                    className="try-now-btn fs_20 fs_lg_18 fs_sm_16 fw_600 px-10 py-2 rounded-md "
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default ContactUsComponent;
