import React from "react";
import access from "../../assets/icons/bussiness-features/access.png";
import payment from "../../assets/icons/bussiness-features/payment-method.png";
import support from "../../assets/icons/bussiness-features/support.png";
import solutions from "../../assets/icons/bussiness-features/solutions.png";
import domain from "../../assets/icons/bussiness-features/browser.png";
import sales from "../../assets/icons/bussiness-features/sales.png";
import siteSpeed from "../../assets/icons/bussiness-features/startup.png";
import chat from "../../assets/icons/bussiness-features/chat.png";
import seo from "../../assets/icons/bussiness-features/seo.png";
import omi from "../../assets/icons/bussiness-features/omi.png";
import { motion } from "framer-motion";

const BusinessFeatures = () => {
  const featuresList = [
    {
      icon: access,
      title: "Role Based Acess",
      description: "Safeguard your business assets with controlled access!",
    },
    {
      icon: payment,
      title: "Multiple Payment Gateway",
      description: "Offer Exclusive payment options to your clients!",
    },
    {
      icon: support,
      title: "Multi-Vendor Support",
      description:
        "Expand Reach and Boost Visibility, onboard multiple vendors. ",
    },
    {
      icon: solutions,
      title: "Scalable Solutions",
      description: "Easy Scalable Options!",
    },
    {
      icon: domain,
      title: "Custom Domain Enabled",
      description: "Strengthen your brand and improve email delivery",
    },
    {
      icon: sales,
      title: "Optimized Checkouts",
      description:
        "Offer a smooth shopping experience to reduce cart abandonment.",
    },
    {
      icon: siteSpeed,
      title: "Site Speed",
      description: "Boost retention with fast, optimized storefronts",
    },
    {
      icon: chat,
      title: "Support",
      description:
        "Quickly resolve issues with dedicated account support for scalable growth.",
    },
    {
      icon: seo,
      title: "SEO Optimized",
      description: "Increase visibility with SEO-optimized product listings",
    },

    {
      icon: omi,
      title: "Omi Channel Support",
      description: "Omni Channel Support where you can concentrate on SALES!",
    },
  ];
  return (
    <section className="lg:py-10 lg:my-10">
      <h1 className="text-center my-10 fs_58 fs_lg_50 fs_md_40 fs_sm_30 fs_xs_24 fw_700">
        Adair for your Growing Business
      </h1>
      <div className="flex flex-wrap justify-between gap-4 lg:w-11/12 mx-auto lg:px-10">
        {featuresList?.map((item, index) => (
          <motion.div
            viewport={{ once: true }}
            initial={{ opacity: 0 }}
            animate="visible"
            whileInView={{
              opacity: 1,
              transition: { delay: index * 0.05 },
            }}
            key={index}
            className="text-center w-10/12 lg:w-full lg:max-w-[24rem] xl:max-w-[24rem] 2xl:max-w-[26.5rem] mb-10 mx-auto lg:mx-0 bg_F1F1F1 py-10 rounded-lg"
          >
            <div className="flex justify-center">
              <img src={item?.icon} alt="icons" width={80} />
            </div>

            <div>
              <p className="fs_26 fs_lg_22 fs_sm_20 fs_xs_18 fw_700">
                {item?.title}
              </p>
              <p className="color_5A5A5A fs_20 fs_lg_18 fs_sm_16 fs_xs_14 px-4">
                {item?.description}
              </p>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default BusinessFeatures;
