import React, { useState } from "react";
import "./navbar.css";
import logo from "../../assets/images/logo.png";
import ContactusModal from "../contact-us/contactus-modal";

const LogoNavbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling animation
    });
  };
  return (
    <section className="flex justify-center lg:fixed bg_f9f9f9 h-fit m-0 top-0">
      <nav className="flex justify-between items-center fixed lg:static bg_f9f9f9 w-screen mx-auto lg:max-w-[90%] p-3">
        <div onClick={handleScrollToTop}>
          <img width={60} src={logo} alt="adair" />
        </div>
        <button
          type="button"
          onClick={() => setIsModalOpen(true)}
          className="lg:flex start-free-btn rounded-md px-3 py-2 fw_600 fs_20 fs_sm_16"
        >
          Start Free
        </button>
      </nav>
      <ContactusModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </section>
  );
};

export default LogoNavbar;
